import { render, staticRenderFns } from "./ModalNotes.vue?vue&type=template&id=3b65ffec&scoped=true&v-if=order.course"
import script from "./ModalNotes.vue?vue&type=script&lang=js"
export * from "./ModalNotes.vue?vue&type=script&lang=js"
import style0 from "./ModalNotes.vue?vue&type=style&index=0&id=3b65ffec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b65ffec",
  null
  
)

export default component.exports