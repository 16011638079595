<template v-if="order.course">
  <b-modal
    id="notes-modal"
    centered
    size="lg"
    :title="'Notes for Order '+order.order+' - '+(order.course ? order.course.course_number : '')"
    no-enforce-focus
  >
    
    <template>
      <ul>
        <li>UC Order ID: {{ order.order }}</li>
        <li>First Name: {{ order.customer ? order.customer.first_name : "" }}</li>
        <li>Last Name: {{ order.customer ? order.customer.last_name : "" }}</li>
        <li>UOP Student ID: {{ order.customer && order.customer.uop_student_id ? order.customer.uop_student_id : "" }}</li>
        <li>Course Number: {{  order.course ? order.course.course_number : "" }}</li>
        <li>Course Title: {{ order.course ? order.course.course_title : "" }}</li>
        <li>Credit(s): {{ order.course_credits }}</li>
        <li>Course Credit Fee: ${{ order.course_credit_fee }}</li>
        <li>Course Fixed Credit Fee: ${{ order.course_fixed_credit_fee }}</li>
        <li>Total Course Credit Fee: ${{ totalCreditFee }}</li>
      </ul>
      
      <div
        v-for="(entry, key) in restrictedAdminNotes" 
        :key="key"
        class="note"
      >
        <div class="mb-3">
          <b-avatar 
            class="mr-2" 
            variant="primary" 
            size="2.5rem"
            v-if="entry.author"
            style="vertical-align: text-bottom; font-size: 1rem"
          >
            <strong>{{entry.author_name.charAt(0)}}</strong>
          </b-avatar>
          <div class="d-inline-block" v-if="entry.author">
            <span><strong>{{entry.author_name}} - {{getReadableRole(entry.author_role)}}</strong></span>
            <br/>
            <span>
              {{formatDate(entry.date)}}
            </span>
            <span v-if="$store.state.user.user_role == 'admin'">
              /  Viewable By: Admin{{entry.uop_admin_can_view ? ", UOP Admin " : ""}}{{entry.partner_can_view ? ", Partner" : ""}}
            </span>
          </div>
          <button
            v-if="$store.state.user.user_role == 'admin'"
            type="button" 
            aria-label="Close" 
            class="close"
            @click="deleteAdminNote(entry._id)"
          >×</button>
        </div>
        <div v-html="entry.note"></div>
      </div>
      <div v-if="!order.admin_notes || order.admin_notes.length == 0">
        <p class="text-center font-weight-bold py-3">No notes have been added yet</p>
      </div>
      <div class="newNoteContainer mt-3">
        <label class="my-3 font-weight-bold">New Note</label>
        <b-form-textarea class="" rows="3"  placeholder="Enter a new note here..." type="text" v-model="newAdminNote" />
        <b-form-group v-if="$store.state.user.user_role == 'admin'" class="mt-2" label="Viewable By:" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
              v-model="newAdminNoteViewableBy"
              :options="[{ text: 'UOP Admins', value: 'uop_admin' },{ text: 'Partners', value: 'partner' }]"
              :aria-describedby="ariaDescribedby"
              name="flavour-1"
            ></b-form-checkbox-group>
        </b-form-group>
      </div>

       
    </template>
    <template
      #modal-footer="{
        /*cancel hide, ok */
      }"
    >
      <b-button
        variant="success"
        @click="addAdminNote()"
        :disabled="stage === 'processing' || newAdminNote == ''"
      >
        Add Note
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  data(){
    return {
        newAdminNote: '',
        newAdminNoteViewableBy: ["uop_admin", "partner"],
        stage: ""
      };
  },
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    stage(newVal){
      if(newVal === 'success' || newVal === 'error') {
        this.$emit('closeDialog')
        this.$root.$emit('bv::hide::modal', 'notes-modal')
      }
    }
  },
  computed: {
    totalCreditFee() {
      return (
        (this.order.course_credits ?? 0) * (this.order.course_credit_fee ?? 0) +
        (this.order.course_fixed_credit_fee ?? 0)
      );
    },
    restrictedAdminNotes() {
      if(!this.order.admin_notes || this.order.admin_notes.length < 1) return [];
      if(this.$store.state.user.user_role == "admin") {
        return this.order.admin_notes
      } else {
        var restrictedAdminNotes = []
        for (const note of this.order.admin_notes) {
          if(
            (this.$store.state.user.user_role == "uop_admin" && note.uop_admin_can_view) ||
            (this.$store.state.user.user_role == "partner" && note.partner_can_view)
          ) {
            restrictedAdminNotes.push(note)
          }
        }
        return restrictedAdminNotes;
      }
    }
  },
  methods: {
    getReadableRole(user_role) {
      var readableRole;
      switch(user_role) {
        case 'admin':
          readableRole = "UC Administrator"
          break;
        case 'uop_admin':
          readableRole = "UOP Administrator"
          break;
        case 'partner':
          readableRole = "Partner"
          break;
        default:
          readableRole = ""
      }
      return readableRole
    },
    addAdminNote() {

      this.stage = 'processing'

      var uop_admin_can_view, partner_can_view;

      if(this.$store.state.user.user_role == 'admin') {
        uop_admin_can_view = (this.newAdminNoteViewableBy.includes("uop_admin"))
        partner_can_view = (this.newAdminNoteViewableBy.includes("partner"))
      } else {
        uop_admin_can_view = true, partner_can_view = true;
      }

      this.$store
        .dispatch("sendPutRequest", {
          endpointPath: "orders/"+this.order._id+"/adminNote",
          _id: this.order._id, 
          body: {
            note: this.newAdminNote,
            partner_can_view: partner_can_view,
            uop_admin_can_view: uop_admin_can_view
          }
        })
        .then((res) => {
          this.makeToast({ message: res.payload.message, variant: "success" });
          this.$emit('closeDialog')
          this.$root.$emit('bv::hide::modal', 'notes-modal')
          this.$parent.changeView();
          this.newAdminNote = ""
          this.newAdminNoteViewableBy = ["uop_admin", "partner"]
          this.stage = ''
        })
        .catch((err) => {
          this.stage = ''
          this.makeToast({ message: err.payload, variant: "danger" });
        });
    },
    deleteAdminNote(noteObjectId) {

      this.stage = 'processing'

      this.$store
        .dispatch("deleteAdminNote", {
          _id: this.order._id,
          noteObjectId: noteObjectId,

        })
        .then((res) => {
          this.makeToast({ message: res.payload.message, variant: "success" });
          this.$emit('closeDialog')
          this.$root.$emit('bv::hide::modal', 'notes-modal')
          this.$parent.changeView();
          this.newAdminNote = ""
          this.stage = ''
        })
        .catch((err) => {
          this.stage = ''
          this.makeToast({ message: err.payload, variant: "danger" });
        });
    },
    formatDate(date) {
      const locale = 'en-US'
      const timeZone = 'America/Los_Angeles'
      return new Date(date).toLocaleDateString(locale, { timeZone: timeZone })+" "+
             new Date(date).toLocaleTimeString(
                locale, 
                { timeZone: timeZone,hour: '2-digit', minute: '2-digit', hour12: true  
              });
    },
  }
};
</script>


<style lang="scss" scoped>
.modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
.newNoteContainer {
  border-top: 1px solid #888;
}
.note {
  background-color: #f7f7f7;
  padding: 1em;
  margin-right: 3rem;
  border-radius: 1rem;
  span {
    font-size: 0.7rem;
  }
}
.note:not(:first-of-type) {
  margin-top: 1rem;
}

</style>